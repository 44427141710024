<script setup lang="ts">
import DatoCuratorCard from './CuratorCard.vue'
import DatoPictureCard from './PictureCard.vue'

import StackedLayout from '~/components/dato/StackedLayout.vue'

import { isNonNullAndDefined } from '~/utils/type-guards'

import type { EntityStackedLayoutFragment } from '~/graphql/generated'

type Props = EntityStackedLayoutFragment

defineProps<Props>()

function getComponentFromItem({ __typename }: Props['entities'][number]) {
  switch (__typename) {
    case 'CuratorCardRecord':
      return markRaw(DatoCuratorCard)
    case 'PictureCardRecord':
      return markRaw(DatoPictureCard)
  }
}
</script>

<template>
  <DatoSectionContainer>
    <StackedLayout v-if="isNonNullAndDefined(entities)" :items="entities">
      <template #default="{ item }">
        <component
          :is="getComponentFromItem(item)"
          v-bind="item"
          class="tw-h-full"
        />
      </template>
    </StackedLayout>
  </DatoSectionContainer>
</template>
