<script setup lang="ts">
import { UIInputText } from '@groover-dev/groover-ui'
import { useToggle } from '@vueuse/core'
import { z } from 'zod'

import DatoSectionContainer from '~/components/dato/DatoSectionContainer.vue'
import VLoadingButton from '~/components/ui/Buttons/VLoadingButton.vue'

import socialLinkRegexes from '~/helpers/mediaLinks/socialLinkRegexes'

import type { AudioAnalyzerFragment } from '~/graphql/generated'

type Props = AudioAnalyzerFragment
type Analyze = {
  embed_url: string
  lyric_summary: string
  mood_predictions: Record<string, number>
  subgenre_predictions: Record<string, number>
  video_title: string
}

const { title, subtitle, placeholder } = defineProps<Props>()

const { t } = useI18n()

const { coreFetch } = useProvideCoreFetch()
const {
  value: input,
  errorMessage: rawInputErrorMessage,
  validate,
} = useFieldValidation<string>(
  'trackLink',
  z
    .string()
    .min(1, t('inputs.invalid_required'))
    .refine(isInputValid, t('inputs.invalid_link')),
  { syncVModel: true, validateOnValueUpdate: true },
)
const inputErrorMessage = ref<string | undefined>(undefined)
const [isLoading, toggleLoadingState] = useToggle()
const helperText = computed(() =>
  isLoading.value ? t('components.audioAnalyzer.waitingMessage') : undefined,
)

function isInputValid(input: string): boolean {
  return (
    socialLinkRegexes.youtube.test(input) ||
    socialLinkRegexes.soundcloud.test(input)
  )
}

function handleInputUpdate(newValue: string) {
  input.value = newValue
  // typing into the input should remove the *display* of the error state
  if (inputErrorMessage.value) inputErrorMessage.value = undefined
}

async function handleAnalyzeButtonClicked() {
  await validate()
  inputErrorMessage.value = rawInputErrorMessage.value
  if (inputErrorMessage.value) return
  toggleLoadingState(true)
  await sendAnalyzeRequest(input.value)
  toggleLoadingState(false)
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  })
}

async function sendAnalyzeRequest(link: string) {
  try {
    // actually handled in the next ticket
    const useMeInNextTicket = await coreFetch.$post<Analyze>(
      'recommendation/audio_analysis/',
      { link },
    )
    // TODO: use useMeInNextTicket
  } catch {}
}
</script>

<template>
  <DatoSectionContainer>
    <div
      class="tw-m-auto tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-8 md:tw-w-5/6"
    >
      <h1
        class="md:!tw-text-6 tw-text-h1 tw-text-center !tw-text-4xl md:!tw-text-6xl"
      >
        {{ title }}
      </h1>
      <p class="tw-self-start tw-text md:tw-self-auto md:tw-text-center">
        {{ subtitle }}
      </p>
      <div
        class="tw-flex tw-w-full tw-flex-col tw-items-start tw-justify-center tw-gap-2 md:tw-w-auto md:tw-flex-row"
      >
        <UIInputText
          class="text-input tw-w-full tw-text-base md:tw-w-72"
          :model-value="input"
          name="trackLink"
          :placeholder="placeholder"
          :is-icon-right-visible="!rawInputErrorMessage"
          :error-text="inputErrorMessage"
          :helper-text="helperText"
          @update:model-value="handleInputUpdate"
        />
        <VLoadingButton
          class="tw-flex tw-w-full tw-justify-center md:tw-w-auto"
          small
          :initial-text="t('components.audioAnalyzer.analyze')"
          :loading-text="t('components.audioAnalyzer.analyzing')"
          loading-class="btn-black"
          :is-loading="isLoading"
          @click="handleAnalyzeButtonClicked"
        />
      </div>
    </div>
  </DatoSectionContainer>
</template>

<style scoped lang="scss">
.text-input > * {
  @apply tw-h-[42px];
}

button.btn-black.btn {
  @apply tw-border-black tw-bg-black tw-text-white;
}
</style>
