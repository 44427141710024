<script lang="ts" setup>
import { UIButton, UIIcon } from '@groover-dev/groover-ui'

import NavbarLogo from '~/components/dato/Navbar/NavbarLogo.vue'
import NavbarMobileLinkListItem from '~/components/dato/Navbar/NavbarMobileLinkListItem.vue'
import LanguageSwitcherV2 from '~/components/ui/LanguageSwitcherV2.vue'

import { useDatoNavbarHelpers } from '~/composables/useDatoNavbarHelpers'

import { isNonNullAndDefined } from '~/utils/type-guards'

import type {
  NavbarFragment,
  StringMultiLocaleField,
} from '~/graphql/generated'

type Props = Omit<NavbarFragment, 'ctas' | '__typename'> & {
  landingPageAvailableLocales?: StringMultiLocaleField[]
}
type Emits = {
  close: []
}

const props = withDefaults(defineProps<Props>(), {
  logo: null,
  leftSideNavLinks: () => [],
  rightSideNavLinks: () => [],
  ctas: () => [],
  landingPageAvailableLocales: () => [],
})
const emit = defineEmits<Emits>()
const menuId = useId()

const isMenuOpen = ref(false)

const iconName = computed(() =>
  isMenuOpen.value ? 'material-symbols:close' : 'fluent:navigation-20-filled',
)

const combinedNavLinkLists = computed(() => [
  ...props.leftSideNavLinks,
  ...props.rightSideNavLinks,
])

const { performCtaAction, getCtaDataTestID, getCtaType } =
  useDatoNavbarHelpers(emit)

function toggleMenu() {
  isMenuOpen.value = !isMenuOpen.value
}
</script>

<template>
  <div>
    <nav
      class="tw-relative tw-flex tw-h-[76px] tw-items-center tw-border-b tw-border-discrete3 tw-bg-fill tw-px-lg"
    >
      <NavbarLogo
        v-if="isNonNullAndDefined(logo)"
        class="tw-flex-1"
        :logo="logo"
      />
      <UIButton
        v-if="isNonNullAndDefined(mobileNavbarCta)"
        :text="mobileNavbarCta.text"
        class="tw-mr-2xl tw-bg-orange-500 tw-px-md"
        size="small"
        @click="performCtaAction(mobileNavbarCta.action, mobileNavbarCta.url)"
      />
      <button
        :aria-expanded="isMenuOpen"
        :aria-controls="menuId"
        type="button"
        class="tw-h-2xl"
        @click="toggleMenu"
      >
        <UIIcon class="tw-text-orange-500" :name="iconName" size="lg" />
      </button>
    </nav>
    <nav
      v-if="isMenuOpen"
      :id="menuId"
      class="tw-h-dvh tw-bg-fill tw-px-lg tw-py-3xl"
    >
      <ul>
        <NavbarMobileLinkListItem
          v-for="navLink in combinedNavLinkLists"
          :key="navLink.id"
          :nav-link="navLink"
          @close-menu="toggleMenu"
        />
      </ul>
      <LanguageSwitcherV2
        class="tw-mt-3xl"
        :landing-page-available-locales
        @switch-language="toggleMenu"
      />
      <div
        v-if="isNonNullAndDefined(mobileMenuCtas)"
        class="tw-mt-3xl tw-flex tw-flex-col tw-gap-y-lg"
      >
        <div v-for="cta in mobileMenuCtas" :key="cta.id">
          <UIButton
            :text="cta.text"
            :variant="getCtaType(cta.ctaType)"
            :data-test-id="getCtaDataTestID(cta.action)"
            size="small"
            class="tw-w-full tw-justify-center tw-px-md"
            :class="{
              'tw-bg-orange-500': cta.ctaType === 'primary',
              '!tw-border-orange-500 !tw-text-orange-500':
                cta.ctaType === 'outlined',
            }"
            @click="performCtaAction(cta.action, cta.url)"
          />
        </div>
      </div>
    </nav>
  </div>
</template>
