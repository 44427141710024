<script setup lang="ts">
import DatoCarouselWithControls from './CarouselWithControls.vue'
import DatoCuratorCard from './CuratorCard.vue'
import DatoPictureCard from './PictureCard.vue'

import DatoSectionContainer from '~/components/dato/DatoSectionContainer.vue'

import { isNonNullAndDefined } from '~/utils/type-guards'

import type { EntityCarouselFragment } from '~/graphql/generated'

type Props = EntityCarouselFragment

defineProps<Props>()

function getComponentFromItem({ __typename }: Props['entities'][number]) {
  switch (__typename) {
    case 'CuratorCardRecord':
      return markRaw(DatoCuratorCard)
    case 'PictureCardRecord':
      return markRaw(DatoPictureCard)
  }
}
</script>
<template>
  <DatoSectionContainer has-no-mobile-margins>
    <DatoCarouselWithControls
      v-if="isNonNullAndDefined(entities)"
      :title="title || ''"
      :items="entities"
    >
      <template #item="{ item }">
        <component
          :is="getComponentFromItem(item)"
          v-bind="item"
          class="tw-h-full"
        />
      </template>
    </DatoCarouselWithControls>
  </DatoSectionContainer>
</template>
