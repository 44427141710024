<script setup lang="ts">
import range from 'lodash.range'

import DarkInfluencerCard from './DarkInfluencerCard.vue'

import DatoSectionContainer from '~/components/dato/DatoSectionContainer.vue'

import { useBandSignupStore } from '~/stores/bandSignup'
import { useInfluencersStore } from '~/stores/influencers'
import { useTagStore } from '~/stores/tag'

import type { StatsV3Influencer } from '~/types/influencer'

type Config = {
  influencers: StatsV3Influencer[]
  title: string
  key: string
}

type Props = {
  genreToInfluencerIdMap?: Record<string, number[]>
  emphasisType?: string | null
}
const props = withDefaults(defineProps<Props>(), {
  genreToInfluencerIdMap: () => ({}),
  emphasisType: null,
})

const { t } = useI18n()

const selectedConfigIndex = ref(0)

const { GET_BY_IDS_SORTED: GET_INFLUENCERS_SORTED_BY_IDS } =
  useInfluencersStore()
const { SET_DISPLAY: BAND_SIGNUP_SET_DISPLAY } = useBandSignupStore()
const { GET_TAG_BY_NAME_FROM_TYPE, FETCH: FETCH_TAGS } = useTagStore()

const allGenresKey = 'all-genres'
const sortOrderByKey: string[] = [
  allGenresKey,
  'pop',
  'hip-hop-rap',
  'electronic',
  'rock-punk',
  'rb-soul',
  'folk-acoustic',
  'chanson',
  'world',
  'metal',
  'jazz',
  'classique-instrumental',
  'reggae',
]
// The mapping for the 'show all' pill
const allGenresCuratorIdMap = (() => {
  const maxGenreLength = Math.max(
    ...Object.values(props.genreToInfluencerIdMap).map(
      (idList) => idList.length,
    ),
  )

  // range crashes when 0 or less
  if (maxGenreLength <= 0) return []

  return {
    [allGenresKey]: range(maxGenreLength).reduce<number[]>((acc, idx) => {
      Object.values(props.genreToInfluencerIdMap).forEach((idList) =>
        idList[idx] ? acc.push(idList[idx]) : undefined,
      )
      return acc
    }, []),
  }
})()
const fullGenreToCuratorIdMap = Object.assign(
  {},
  allGenresCuratorIdMap,
  props.genreToInfluencerIdMap,
)
const configs = computed<Config[]>(() =>
  Object.entries(fullGenreToCuratorIdMap)
    .reduce<Config[]>((accumulator, [genreKey, influencerIds]) => {
      accumulator.push({
        title: t(`parentTags.subgenre.${genreKey}`),
        influencers: GET_INFLUENCERS_SORTED_BY_IDS(influencerIds),
        key: genreKey,
      })
      return accumulator
    }, [])
    .toSorted((a, b) => {
      const aIndex = sortOrderByKey.indexOf(a.key)
      const bIndex = sortOrderByKey.indexOf(b.key)

      return aIndex >= 0 && bIndex >= 0
        ? sortOrderByKey.length - bIndex - (sortOrderByKey.length - aIndex)
        : aIndex === -1
          ? 1
          : -1
    }),
)

const selectedConfigInfluencers = computed<StatsV3Influencer[]>(() => {
  if (configs.value[selectedConfigIndex.value]) {
    // N.B: if a specific curator type was asked to be emphasized, we want to alternate
    // between a Curator of that type and one of an other
    const emphasisTypeId =
      props.emphasisType &&
      GET_TAG_BY_NAME_FROM_TYPE(props.emphasisType, 'influencer_kind')?.id
    if (!emphasisTypeId)
      return configs.value[selectedConfigIndex.value].influencers.slice(0, 18)
    const emphasisSubList = configs.value[
      selectedConfigIndex.value
    ].influencers.filter((curator) =>
      curator.tags.identity.influencer_kind.includes(emphasisTypeId),
    )
    const nonEmphasisSubList = configs.value[
      selectedConfigIndex.value
    ].influencers.filter(
      (curator) =>
        !curator.tags.identity.influencer_kind.includes(emphasisTypeId),
    )
    const maxSubListLength = Math.max(
      emphasisSubList.length,
      nonEmphasisSubList.length,
    )

    if (maxSubListLength <= 0) return []
    return range(maxSubListLength)
      .reduce<StatsV3Influencer[]>((acc, idx) => {
        if (idx < emphasisSubList.length) acc.push(emphasisSubList[idx])
        if (idx < nonEmphasisSubList.length) acc.push(nonEmphasisSubList[idx])
        return acc
      }, [])
      .slice(0, 18)
  } else {
    return []
  }
})

// N.B: The items seem to be put twice so that the animated display works properly
const duplicatedFirstRow = computed(() => [
  ...selectedConfigInfluencers.value.slice(0, 6),
  ...selectedConfigInfluencers.value.slice(0, 6),
])

const duplicatedSecondRow = computed(() => [
  ...selectedConfigInfluencers.value.slice(6, 12),
  ...selectedConfigInfluencers.value.slice(6, 12),
])

const duplicatedThirdRow = computed(() => [
  ...selectedConfigInfluencers.value.slice(12, 18),
  ...selectedConfigInfluencers.value.slice(12, 18),
])

function handleCardClick(influencer: StatsV3Influencer): void {
  BAND_SIGNUP_SET_DISPLAY(true)
}

function handleSelectGenre(index: number): void {
  selectedConfigIndex.value = index
}

await callOnce(FETCH_TAGS)
</script>

<template>
  <div>
    <DatoSectionContainer has-no-mobile-margins>
      <div
        class="tw-mb-4 tw-flex tw-w-[735px] tw-flex-col tw-gap-4 tw-overflow-auto md:tw-mr-0 lg:tw-w-full"
      >
        <div class="tw-m-auto tw-flex tw-gap-2 md:tw-justify-center">
          <div
            v-for="(config, index) in configs.slice(0, configs.length / 2)"
            :key="config.key"
            class="selectionPill tw-text-body tw-cursor-pointer tw-select-none tw-whitespace-nowrap tw-rounded-full tw-border tw-border-dashed tw-border-discrete3 tw-px-3 tw-py-2 tw-text-default-color"
            :class="{
              'tw-border-none tw-bg-fill-alt tw-text-inverse':
                index === selectedConfigIndex,
            }"
            @click="handleSelectGenre(index)"
          >
            {{ config.title }}
          </div>
        </div>
        <div class="tw-m-auto tw-flex tw-gap-2 md:tw-justify-center">
          <div
            v-for="(config, index) in configs.slice(configs.length / 2)"
            :key="config.key"
            class="selectionPill tw-text-body tw-cursor-pointer tw-select-none tw-whitespace-nowrap tw-rounded-full tw-border tw-border-dashed tw-border-discrete3 tw-px-3 tw-py-2 tw-text-default-color"
            :class="{
              'tw-border-none tw-bg-fill-alt tw-text-inverse':
                Math.floor(configs.length / 2) + index === selectedConfigIndex,
            }"
            @click="handleSelectGenre(Math.floor(configs.length / 2) + index)"
          >
            {{ config.title }}
          </div>
        </div>
      </div>
    </DatoSectionContainer>
    <DatoSectionContainer>
      <!-- Next line reverses container effect on smaller screens -->
      <div class="tw--mx-4 tw-overflow-hidden md:tw--mx-5 lg:tw-mx-auto">
        <div
          class="tw-m-auto tw-flex tw-flex-col tw-gap-1 tw-overflow-hidden lg:tw-border-x lg:tw-border-default-color lg:tw-py-4"
        >
          <div class="animate-scroll-left tw-flex tw-w-max tw-gap-1">
            <DarkInfluencerCard
              v-for="(influencer, index) in duplicatedFirstRow"
              :key="`${influencer.id}-${index}`"
              :influencer="influencer"
              class="tw-cursor-pointer"
              @click="handleCardClick(influencer)"
            />
          </div>
          <div class="animate-scroll-right tw-flex tw-w-max tw-gap-1">
            <DarkInfluencerCard
              v-for="(influencer, index) in duplicatedSecondRow"
              :key="`${influencer.id}-${index}`"
              :influencer="influencer"
              class="tw-cursor-pointer"
              @click="handleCardClick(influencer)"
            />
          </div>
          <div class="animate-scroll-left tw-flex tw-w-max tw-gap-1">
            <DarkInfluencerCard
              v-for="(influencer, index) in duplicatedThirdRow"
              :key="`${influencer.id}-${index}`"
              :influencer="influencer"
              class="tw-cursor-pointer"
              @click="handleCardClick(influencer)"
            />
          </div>
        </div>
      </div>
    </DatoSectionContainer>
  </div>
</template>

<style scoped lang="scss">
@keyframes scrollLeft {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

@keyframes scrollRight {
  0% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(0);
  }
}
@media screen and (prefers-reduced-motion: no-preference) {
  .animate-scroll-left {
    animation: scrollLeft 120s linear infinite;
  }

  .animate-scroll-right {
    animation: scrollRight 120s linear infinite;
  }
}
</style>
