<script setup lang="ts">
import { UIButton } from '@groover-dev/groover-ui'

import { useGetDatoUrlForLink } from '~/composables/useGetDatoUrlForLink'

import { useBandSignupStore } from '~/stores/bandSignup'
import { useUserStore } from '~/stores/user'

import type { CtaFragment } from '~/graphql/generated'

type Props = CtaFragment

type Emits = {
  close: []
}

const props = withDefaults(defineProps<Props>(), {
  text: '',
  type: 'primary',
  showSignupModal: false,
  url: '',
})
const emit = defineEmits<Emits>()

const { IS_LOGGED_IN, KIND: USER_KIND } = storeToRefs(useUserStore())
const { SET_DISPLAY: BAND_SIGNUP_SET_DISPLAY } = useBandSignupStore()

const localePath = useLocalePath()
const { getUrl, isExternalLink: checkIfExternal } = useGetDatoUrlForLink()

const isExternalLink = computed(() =>
  Boolean(checkIfExternal(props.url) && !props.showSignupModal),
)

function handleSignupClick() {
  if (!props.showSignupModal) return

  if (IS_LOGGED_IN.value) {
    return USER_KIND.value === 'band'
      ? navigateTo(localePath('/draft'))
      : navigateTo(localePath('/'))
  }

  BAND_SIGNUP_SET_DISPLAY(true)
  emit('close')
}
</script>

<template>
  <UIButton
    :text="text"
    :variant="props.ctaType === 'primary' ? 'primary' : 'outlined'"
    :class="{
      'tw-bg-orange-500': props.ctaType === 'primary',
      '!tw-border-orange-500 !tw-text-orange-500': props.ctaType === 'outlined',
    }"
    size="large"
    :to="showSignupModal ? undefined : getUrl(url) || undefined"
    :target="isExternalLink ? '_blank' : undefined"
    @click="handleSignupClick"
  />
</template>
