import { useLanguage } from '~/composables/useLanguage'

/**
 * `formatNumberCompact` is used to transform 5000 to 5K. It handles all localization and any exponent.
 *
 * @returns Util functions to localize number formating
 */
export function useFormatNumber() {
  const { getCurrentLanguage } = useLanguage()
  const { format: formatNumberCompact } = new Intl.NumberFormat(
    getCurrentLanguage(),
    {
      notation: 'compact',
    },
  )

  return { formatNumberCompact } as const
}
