export function useGetDatoUrlForLink() {
  const route = useRoute()
  const localePath = useLocalePath()

  function isExternalLink(url: string | null) {
    if (!url) return false
    return url.startsWith('http')
  }

  /**
   * Checks the URL to see if it is a same-page link based on what we can recieve from DatoCMS.
   *
   * @param url - The URL to check.
   * @returns - Whether the URL is a same-page link.
   */
  function isSamePageLink(url: string | null) {
    if (!url) return false
    return url.startsWith('/#') || url?.startsWith('#')
  }

  /**
   * Formats the given URL from dato for use within a link. Note that when the link is internal `localePath` is already called
   *
   * @param url - The URL to check and format.
   * @returns - The formatted URL.
   */
  function getUrl(url: string | null = '') {
    return isExternalLink(url)
      ? url
      : isSamePageLink(url)
        ? `${route.path}${url}`.replaceAll('//', '/')
        : localePath(url || '')
  }

  return {
    getUrl,
    isExternalLink,
  }
}
