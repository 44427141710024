import { storeToRefs } from 'pinia'

import { useProvideCoreFetch } from '~/composables/useProvideCoreFetch'

import { useInfluencersStore } from '~/stores/influencers'
import { useUserStore } from '~/stores/user'

import { provideWelcomeSpotlight } from '~/api-core/Welcome/Spotilight'

import type { Locale } from '~/types/locale'

export function useGetWelcomeSpotlight() {
  const { $pinia } = useNuxtApp()
  const { coreFetch } = useProvideCoreFetch()
  const { FETCH_SET: FETCH_INFLUENCER_SET } = useInfluencersStore($pinia)

  async function getLandingPageInfluencers(
    locale: Locale,
    fallbackLocale: Locale = 'en',
  ): Promise<Record<string, number[]>> {
    const getSpotilightInfluencers = provideWelcomeSpotlight(coreFetch)
    try {
      const genreToInfluencerRelation = await getSpotilightInfluencers(
        locale,
        fallbackLocale,
      )

      const genreToInfluencerIdRelation = Object.entries(
        genreToInfluencerRelation,
      ).reduce<Record<string, number[]>>((accumulator, [key, value]) => {
        accumulator[key] = value.map(
          (partialInfluencer) => partialInfluencer.id,
        )
        return accumulator
      }, {})

      await FETCH_INFLUENCER_SET([
        ...Object.values(genreToInfluencerIdRelation).reduce(
          (accumulator, values) => {
            values.forEach((influencerId) => {
              accumulator.add(influencerId)
            })
            return accumulator
          },
          new Set<number>(),
        ),
      ])
      return genreToInfluencerIdRelation
    } catch (err) {
      return {}
    }
  }

  return { getLandingPageInfluencers } as const
}
