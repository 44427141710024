<script setup lang="ts">
import { UIIcon } from '@groover-dev/groover-ui'
import { storeToRefs } from 'pinia'
import { Image as DatocmsImage } from 'vue-datocms'

import DatoSectionContainer from '~/components/dato/DatoSectionContainer.vue'
import HeightExpand from '~/components/transitions/heightExpand.vue'

import { useMiscResizeStore } from '~/stores/miscResize'

import { isNonNullAndDefined } from '~/utils/type-guards'

import { Breakpoints } from '~/enums/breakpoints'

import type { IconListFragment } from '~/graphql/generated'

type Props = IconListFragment

withDefaults(defineProps<Props>(), {
  items: () => [],
})

const { SCREEN_WIDTH } = storeToRefs(useMiscResizeStore())

const openIndex = ref(0)
const showAll = computed(() => SCREEN_WIDTH.value >= Breakpoints.md)

const itemComponent = computed(() => {
  return SCREEN_WIDTH.value >= Breakpoints.md ? 'div' : 'button'
})

function toggleItem(index: number) {
  openIndex.value = openIndex.value === index ? -1 : index
}
</script>

<template>
  <DatoSectionContainer>
    <div
      class="iconList tw-w-full tw-rounded-2xl tw-py-xl md:tw-bg-fill-alt md:tw-px-3xl md:tw-py-5xl"
    >
      <div
        class="tw-grid tw-gap-2xs md:tw-grid-flow-col-dense md:tw-flex-row md:tw-gap-lg"
      >
        <div
          v-for="(item, index) in items"
          :key="index"
          class="tw-flex tw-flex-col tw-overflow-hidden tw-rounded-lg tw-border-discrete tw-bg-fill-alt tw-text-inverse md:tw-gap-sm md:tw-rounded-none md:tw-bg-none md:tw-pl-lg md:tw-pt-zero"
          :class="{ 'md:tw-border-l': index !== 0 }"
        >
          <component
            :is="itemComponent"
            class="tw-text-body tw-flex tw-w-full tw-items-center tw-justify-between tw-p-lg tw-text-left md:tw-cursor-default md:tw-p-zero"
            type="button"
            @click="toggleItem(index)"
          >
            <div
              class="tw-flex tw-items-center tw-gap-sm md:tw-flex-col md:tw-items-start"
            >
              <!-- For responsive image types (png, jpg, etc.) -->
              <DatocmsImage
                v-if="isNonNullAndDefined(item.icon?.responsiveImage)"
                :data="item.icon.responsiveImage"
                class="tw-not-prose tw-size-lg md:tw-size-2xl"
              />
              <!-- For SVG images -->
              <img
                v-else
                :src="item.icon?.url"
                :alt="item.icon?.alt || ''"
                class="tw-h-lg tw-w-auto md:tw-h-2xl"
              />
              <b>{{ item.title }}</b>
            </div>
            <span
              class="tw-flex tw-transform tw-transition-transform tw-duration-300 md:tw-hidden"
              :class="{ 'tw-rotate-[135deg]': openIndex === index }"
            >
              <UIIcon name="mdi:add" class="tw-text-icon-discrete3" />
            </span>
          </component>
          <HeightExpand>
            <div v-if="openIndex === index || showAll">
              <!-- Child div is required to avoid animation jumps -->
              <div
                class="tw-text-body-sm tw-px-lg tw-pb-lg md:tw-px-zero md:tw-pb-zero"
              >
                {{ item.description }}
              </div>
            </div>
          </HeightExpand>
        </div>
      </div>
    </div>
  </DatoSectionContainer>
</template>
