<script setup lang="ts">
import { UIButtonLink } from '@groover-dev/groover-ui'
import { NuxtLinkLocale } from '#components'
import { useGetDatoUrlForLink } from '~~/composables/useGetDatoUrlForLink'
import { Image as DatocmsImage } from 'vue-datocms'

import { useBandSignupStore } from '~/stores/bandSignup'

import { hasSlotContent } from '~/utils/hasSlot'
import { isNonNullAndDefined } from '~/utils/type-guards'

import type { ResponsiveImage } from '~/graphql/generated'

type Props = {
  responsiveImage?: Omit<ResponsiveImage, 'aspectRatio'> | null
  link?: string | null
  openSignupModal?: boolean
}

type Slots = {
  title(): any
  subtitle(): any
  tags(): any
  description(): any
  linkText(): any
}

const props = withDefaults(defineProps<Props>(), {
  openSignupModal: false,
})
const slots = defineSlots<Slots>()

const { DISPLAY: DISPLAY_BAND_SIGNUP_MODAL } = useBandSignupStore()

const { getUrl, isExternalLink } = useGetDatoUrlForLink()

function handleOpenSignupModal() {
  if (!props.openSignupModal) return
  DISPLAY_BAND_SIGNUP_MODAL()
}
</script>

<template>
  <component
    :is="openSignupModal ? 'div' : NuxtLinkLocale"
    :to="getUrl(link) || undefined"
    :external="isExternalLink(link || null)"
    @click="handleOpenSignupModal"
    class="tw-flex tw-w-full tw-cursor-pointer tw-flex-col tw-items-start tw-gap-sm tw-rounded-lg tw-border-2 tw-border-transparent tw-bg-neutral-900 tw-p-sm tw-transition-colors hover:tw-border-primary"
  >
    <div class="tw-flex tw-items-center tw-justify-start tw-gap-sm">
      <div
        class="tw-h-7xl tw-w-7xl tw-flex-shrink-0 tw-overflow-hidden tw-rounded-md tw-object-center md:tw-h-8xl md:tw-w-8xl"
      >
        <ClientOnly v-if="isNonNullAndDefined(responsiveImage)">
          <DatocmsImage :data="responsiveImage" />
        </ClientOnly>
      </div>
      <div class="tw-grid tw-gap-2xs">
        <h6
          v-if="hasSlotContent(slots.title)"
          class="clamp-2 tw-text-base tw-text-primary lg:tw-text-h6"
        >
          <slot name="title" />
        </h6>
        <div
          v-if="hasSlotContent(slots.subtitle)"
          class="tw-ellipsis tw-text-base tw-text-white"
        >
          <slot name="subtitle" />
        </div>
        <div
          v-if="hasSlotContent(slots.tags)"
          class="tw-flex tw-flex-wrap tw-gap-2xs"
        >
          <slot name="tags" />
        </div>
      </div>
    </div>
    <div
      v-if="hasSlotContent(slots.description)"
      class="clamp-5 tw-whitespace-pre-line tw-text-wrap tw-text-base tw-text-white"
    >
      <slot name="description" />
    </div>
    <UIButtonLink
      v-if="isNonNullAndDefined(link) || openSignupModal"
      :to="openSignupModal ? '' : link || ''"
      @click="handleOpenSignupModal"
      icon-right-name="material-symbols:chevron-right"
      class="tw-mt-auto"
    >
      <slot name="linkText">
        {{ $t('dato.curatorCard.submitMyMusic') }}
      </slot>
    </UIButtonLink>
  </component>
</template>

<style lang="scss" scoped>
.clamp-5 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  overflow: hidden;
}

.clamp-2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
</style>
