<script setup lang="ts">
import {
  Image as DatocmsImage,
  StructuredText as DatocmsStructuredText,
} from 'vue-datocms'

import Cta from '~/components/dato/Cta.vue'
import DatoSectionContainer from '~/components/dato/DatoSectionContainer.vue'

import { isNonNullAndDefined } from '~/utils/type-guards'

import type { HeroBoxFragment, HeroFragment } from '~/graphql/generated'

type Props = HeroFragment | HeroBoxFragment

const props = withDefaults(defineProps<Props>(), {
  illustration: undefined,
  slideshowImages: () => [],
  ctas: () => [],
  heroSubtitle: null,
  subtitle: null,
  title: null,
})

const currentSlideshowImage = ref<HeroBoxFragment['slideshowImages'][0] | null>(
  props.slideshowImages.length ? props.slideshowImages[0] : null,
)
const SLIDESHOW_DELAY_TIME_IN_MS = 6500
const slideshowTimeout = ref<NodeJS.Timeout | null>(null)

onMounted(() => {
  if (!props.slideshowImages.length || props.slideshowImages.length < 2) return

  slideshowTimeout.value = setInterval(
    () => setSlideshowImage(),
    SLIDESHOW_DELAY_TIME_IN_MS,
  )
})

onBeforeUnmount(() => clearSlideshowTimeout())

function setSlideshowImage() {
  const currentIndex = props.slideshowImages.findIndex(
    (image) => image.id === currentSlideshowImage.value?.id,
  )
  const slideshowLength = props.slideshowImages?.length || 0
  const nextIndex = slideshowLength === currentIndex + 1 ? 0 : currentIndex + 1
  currentSlideshowImage.value = props.slideshowImages[nextIndex]
}

function clearSlideshowTimeout() {
  if (slideshowTimeout.value) clearInterval(slideshowTimeout.value)
}
</script>

<template>
  <DatoSectionContainer>
    <div
      id="hero"
      class="tw-flex tw-flex-col tw-gap-4xl md:tw-flex-row md:tw-items-center md:tw-gap-lg lg:tw-gap-[122px]"
    >
      <div
        :class="[
          'tw-flex tw-flex-col tw-justify-center',
          {
            'md:tw-w-1/2': illustration?.responsiveImage,
            'md:tw-items-center': !illustration?.responsiveImage,
          },
        ]"
      >
        <h1
          v-if="title"
          class="tw-text-h2 tw-mb-lg md:tw-text-h1"
          :class="{
            'tw-text-center md:tw-text-left': illustration?.responsiveImage,
            'tw-text-center md:tw-w-10/12': !illustration?.responsiveImage,
          }"
        >
          {{ title }}
        </h1>
        <div
          v-if="heroSubtitle || subtitle"
          :class="{
            'tw-text-center md:tw-text-left': illustration?.responsiveImage,
            'md:tw-w-8/12 md:tw-text-center': !illustration?.responsiveImage,
          }"
        >
          <DatocmsStructuredText
            :data="heroSubtitle || subtitle"
            class="tw-text-body"
          />
        </div>
        <div class="tw-mt-3xl tw-flex tw-flex-col tw-gap-lg md:tw-flex-row">
          <Cta v-for="cta in ctas" :key="cta.id" v-bind="cta" />
        </div>
      </div>
      <div
        v-if="slideshowImages?.length"
        class="tw-relative md:tw-flex md:tw-items-center lg:tw-block"
      >
        <ClientOnly>
          <div
            v-for="slideshowImage in slideshowImages"
            :id="slideshowImage.id"
            :key="slideshowImage.id"
            ref="datoImgs"
            class="tw-overflow-hidden"
          >
            <Transition name="fade">
              <!-- use v-show here vs. v-if so screen readers can read all img alt content, not just the active slideshow image -->
              <DatocmsImage
                v-show="
                  isNonNullAndDefined(currentSlideshowImage?.responsiveImage) &&
                  currentSlideshowImage?.id === slideshowImage.id
                "
                :data="slideshowImage.responsiveImage"
                class="dato-img tw-rounded-lg"
              />
            </Transition>
          </div>
        </ClientOnly>
      </div>
      <!-- Old single image version -->
      <div
        v-else-if="illustration?.responsiveImage"
        class="tw-relative md:tw-flex md:tw-w-1/2 md:tw-items-center"
      >
        <DatocmsImage
          v-if="isNonNullAndDefined(illustration.responsiveImage)"
          :data="illustration.responsiveImage"
          class="dato-img tw-rounded-lg"
        />
      </div>
    </div>
  </DatoSectionContainer>
</template>

<style scoped lang="scss">
.dato-img {
  :deep(img) {
    @apply tw-max-w-full tw-object-contain;
  }

  // Override dato's max-width for mobile only
  @media screen and (max-width: 768px) {
    max-width: 100% !important;
  }
}

.fade-leave-active {
  @apply tw-left-0 tw-top-1/2 -tw-translate-y-1/2;
  position: absolute !important;
}

.fade-enter-active,
.fade-leave-active {
  @apply tw-transition-opacity tw-duration-[800ms] tw-ease-linear;
}

.fade-enter-active {
  @apply tw-delay-[800ms];
}

#boxShadowEl.fade-enter-active {
  @apply tw-delay-0 tw-duration-500;
}

.fade-enter-from,
.fade-leave-to {
  @apply tw-opacity-0;
}
</style>
