<script setup lang="ts">
import { UIIcon } from '@groover-dev/groover-ui'
import { StructuredText as DatocmsStructuredText } from 'vue-datocms'

import DatoSectionContainer from '~/components/dato/DatoSectionContainer.vue'
import HeightExpand from '~/components/transitions/heightExpand.vue'

import type { AccordionFragment } from '~/graphql/generated'

type Props = AccordionFragment

withDefaults(defineProps<Props>(), {
  title: '',
  items: () => [],
})

const openIndex = ref(0)

function toggleItem(index: number) {
  openIndex.value = openIndex.value === index ? -1 : index
}
</script>

<template>
  <DatoSectionContainer>
    <div
      class="accordion tw-grid tw-w-full tw-grid-cols-1 tw-flex-col tw-gap-3xl md:tw-grid-cols-[3fr_9fr] md:tw-gap-lg lg:tw-grid-cols-[4fr_8fr]"
    >
      <!-- left panel -->
      <h2 class="tw-text-h3 tw-text-center md:tw-text-left">
        {{ title }}
      </h2>
      <!-- right panel -->
      <div class="tw-flex tw-flex-col tw-gap-2xs">
        <div
          v-for="(item, index) in items"
          :key="index"
          class="faqAccordionCard tw-flex tw-flex-col tw-rounded-lg tw-bg-default"
        >
          <h3 class="tw-text-h5">
            <button
              :id="`faqAccordionTitleButton-${index}`"
              class="tw-flex tw-w-full tw-items-center tw-justify-between tw-p-lg tw-text-left tw-text-default-color md:tw-px-2xl"
              :aria-controls="`faqAccordionContent-${index}`"
              :aria-expanded="openIndex === index"
              @click="toggleItem(index)"
            >
              {{ item.title }}
              <span
                class="tw-flex tw-transform tw-transition-transform tw-duration-300"
                :class="{ 'tw-rotate-[135deg]': openIndex === index }"
                aria-hidden
              >
                <UIIcon name="mdi:add" />
              </span>
            </button>
          </h3>
          <HeightExpand>
            <div
              v-show="openIndex === index"
              :id="`faqAccordionContent-${index}`"
              :aria-labelledby="`faqAccordionTitleButton-${index}`"
              class="tw-overflow-hidden"
            >
              <DatocmsStructuredText
                :data="item.multiLineDescription"
                class="prose-accordion tw-px-lg tw-pb-lg md:tw-px-2xl"
              />
            </div>
          </HeightExpand>
        </div>
      </div>
    </div>
  </DatoSectionContainer>
</template>

<style scoped lang="scss">
:deep(.prose-accordion) {
  // Hide these to control spacing between elements with tags above
  br {
    display: none;
  }
}
</style>
