<script lang="ts" setup generic="T extends { id: number | string }">
import { UIIcon } from '@groover-dev/groover-ui'
import { storeToRefs } from 'pinia'

import { useMiscResizeStore } from '~/stores/miscResize'

import { Breakpoints } from '~/enums/breakpoints'

import type { StyleRecord } from '~/types/styleRecord'

type Props = {
  title: string
  items: T[]
}

type Slots = {
  item(data: { item: T }): any
}

const props = defineProps<Props>()
defineSlots<Slots>()

const { SCREEN_WIDTH } = storeToRefs(useMiscResizeStore())

const itemsProxy = ref([...props.items]) as globalThis.Ref<T[]>
setupArray()

const itemsRef = useTemplateRef('itemsRef')
const unorderedListRef = useTemplateRef('unorederedList')
const transitionName = ref<'left' | 'right'>('left')
const innerRef = useTemplateRef('inner')
const isTransitioning = ref(false)

const nextStepPx = computed(() => {
  if (!itemsRef.value) return 0

  const targetIndex =
    transitionName.value === 'left' ? 0 : itemsProxy.value.length - 1
  return itemsRef.value[targetIndex]?.scrollWidth ?? 0
})

const ariaControlUid = useId()
const innerStyles = ref<Partial<StyleRecord<'transform' | 'transition'>>>({})

watch(SCREEN_WIDTH, (to, from) => {
  if (unorderedListRef.value) unorderedListRef.value.scrollLeft = 0
  if (to >= Breakpoints.lg && from < Breakpoints.lg) return setupArray()
  if (to < Breakpoints.lg && from >= Breakpoints.lg) {
    innerStyles.value = {}
    setupArray()
  }
})

function setupArray() {
  itemsProxy.value = [...props.items]

  if (SCREEN_WIDTH.value < Breakpoints.lg) return
  // Offset by one to handle initial translate offset
  const tmpItem = itemsProxy.value.pop()
  if (tmpItem) itemsProxy.value.unshift(tmpItem)
}

function move(direction: 'left' | 'right') {
  if (isTransitioning.value) return
  transitionName.value = direction
  innerStyles.value = {
    transform:
      direction === 'left'
        ? `translateX(0px)`
        : `translateX(-${nextStepPx.value * 2}px)`,
  }

  afterTransition(() => {
    const item = itemsProxy.value[direction === 'left' ? 'pop' : 'shift']()

    if (item) itemsProxy.value[direction === 'left' ? 'unshift' : 'push'](item)
    resetInnerStyles()
  })
}

function afterTransition(callback: () => void) {
  if (!innerRef.value) return
  const listener = () => {
    if (!innerRef.value) return
    innerRef.value.removeEventListener('transitionend', listener)
    callback()
  }

  innerRef.value.addEventListener('transitionend', listener)
}

function resetInnerStyles() {
  innerStyles.value = {
    transition: 'none',
    transform: `translateX(-${nextStepPx.value}px)`,
  }
  isTransitioning.value = false
}
</script>
<template>
  <div class="tw-relative tw-grid tw-w-full tw-gap-2xl lg:tw-flex lg:tw-gap-0">
    <div
      class="borderColor tw-w-full tw-items-center tw-justify-center tw-text-center lg:tw-grid lg:tw-max-w-sm lg:tw-border-r lg:tw-p-sm lg:tw-text-left"
    >
      <h3 class="tw-text-h3">{{ title }}</h3>
      <div class="tw-z-10 tw-hidden tw-gap-lg tw-p-sm lg:tw-flex">
        <button
          :aria-controls="ariaControlUid"
          class="chevronWrapper"
          @click="move('left')"
        >
          <UIIcon aria-hidden name="material-symbols:chevron-left" />
        </button>
        <button
          :aria-controls="ariaControlUid"
          class="chevronWrapper"
          @click="move('right')"
        >
          <UIIcon aria-hidden name="material-symbols:chevron-right" />
        </button>
      </div>
    </div>
    <ul
      ref="unorederedList"
      class="borderColor tw-z-0 tw-w-full tw-overflow-x-scroll tw-pl-xl lg:tw-overflow-hidden lg:tw-border-r lg:tw-px-sm"
    >
      <div
        ref="inner"
        class="tw-flex tw-transition-all tw-duration-150"
        :style="innerStyles"
        :id="ariaControlUid"
      >
        <keep-alive>
          <li
            v-for="item in itemsProxy"
            :key="item.id"
            ref="itemsRef"
            class="tw-pr-lg lg:tw-pr-sm"
          >
            <div
              class="tw-relative tw-h-full tw-w-[300px] md:tw-w-[294px] lg:tw-w-[334px]"
            >
              <slot name="item" v-bind="{ item }" />
            </div>
          </li>
        </keep-alive>
      </div>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
.chevronWrapper {
  @apply tw-cursor-pointer tw-rounded-sm tw-bg-fill-primary tw-p-sm tw-text-white;
}

.borderColor {
  @apply tw-border-default;
}
</style>
<style lang="scss">
.tw-bg-fill-alt {
  .borderColor {
    @apply tw-border-white;
  }
}
</style>
