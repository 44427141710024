<script setup lang="ts">
import { storeToRefs } from 'pinia'

import ChartsShareCanvas from '~/components/track/ChartsShareCanvas.vue'
import ColorPalettePicker from '~/components/ui/ColorPalettePicker.vue'
import ShareTrackInstagramTemplate from '~/components/ui/ShareTrackInstagramTemplate.vue'

import { useSegmentTrack } from '~/composables/Segment/useSegmentTrack'
import { useProvideCoreFetch } from '~/composables/useProvideCoreFetch'
import { useSharePictureOnInstagram } from '~/composables/useSharePictureOnInstagram'

import { useUserStore } from '~/stores/user'

import { getTrackDistantApiData } from '~/helpers/distantApi/getTrackDistantApi'

import type { ChartsTrack } from '~/types/ChartsTrack'

type Props = {
  chartsTrack: ChartsTrack
}

const props = defineProps<Props>()

const display = defineModel<boolean>('display', {
  default: () => true,
})

const { sharePictureOnInstagram } = useSharePictureOnInstagram()

const segmentTrack = useSegmentTrack()

const { id: USER_ID } = storeToRefs(useUserStore())
const { t } = useI18n()
const { coreFetch } = useProvideCoreFetch()
const TrackShareCanvasReference = useTemplateRef('track-share-canvas')
const loading = ref(false)

const trackPictureUrl = ref<null | string>(null)

const COLORS = [
  '#EBA64D',
  '#D3ADEF',
  '#F9885E',
  '#528BDD',
  '#F7F6F0',
  '#2B2B2B',
] as const
const availableColors = ref([...COLORS])
const selectedColor = ref<(typeof COLORS)[number]>('#528BDD')
const accentColor = computed(() => {
  switch (selectedColor.value) {
    case '#EBA64D':
      return '#FBBA66'
    case '#D3ADEF':
      return '#BA88DE'
    case '#F9885E':
      return '#EB512F'
    case '#528BDD':
      return '#1C6BB8'
    case '#F7F6F0':
      return '#E5E5E5'
    case '#2B2B2B':
      return '#1D1D1D'
  }
})

if (!display.value) watchOnce(() => display.value, getTrackImage)
else onMounted(getTrackImage)

async function getTrackImage() {
  const data = await getTrackDistantApiData(
    coreFetch,
    props.chartsTrack.track_link,
  )

  if (data.err) return null
  trackPictureUrl.value = data.thumbnail
}

function getPicture() {
  if (!TrackShareCanvasReference.value) return null

  return TrackShareCanvasReference.value.getImage()
}

async function handleButtonClick() {
  const picture = getPicture()

  loading.value = true
  await sharePictureOnInstagram(picture)
  if (USER_ID.value)
    segmentTrack('Charts - Share on Instagram Clicked', {
      id_track: props.chartsTrack.track_id,
      track_position: props.chartsTrack.rank,
      track_title: props.chartsTrack.track_name,
      user_id: USER_ID.value,
    })
  loading.value = false
  display.value = false
}
</script>

<template>
  <ShareTrackInstagramTemplate
    :track-link="chartsTrack.track_link"
    :loading="!trackPictureUrl || loading"
    v-model="display"
    @share="handleButtonClick"
  >
    <template #title>{{
      t(
        'components.band.dashboard.new.submissionDetails.influencerSharedLinkDisplay.share',
      )
    }}</template>
    <template #canvas>
      <ChartsShareCanvas
        v-if="trackPictureUrl"
        ref="track-share-canvas"
        :track-picture-url
        :track-title="chartsTrack.track_name"
        :band-name="chartsTrack.band_name"
        :accent-color
        :selected-color
        :rank="chartsTrack.rank"
        :descriptor="$t('charts.shareCanvas.descriptor.default')"
      />
    </template>
    <template #colorPicker>
      <ColorPalettePicker
        :available-colors
        v-model:selected-color="selectedColor"
      />
    </template>
  </ShareTrackInstagramTemplate>
</template>
