<script setup lang="ts">
import { UIButtonLink } from '@groover-dev/groover-ui'
import {
  Image as DatocmsImage,
  StructuredText as DatocmsStructuredText,
} from 'vue-datocms'

import DatoSectionContainer from '~/components/dato/DatoSectionContainer.vue'

import { useGetDatoUrlForLink } from '~/composables/useGetDatoUrlForLink'

import { isNonNullAndDefined } from '~/utils/type-guards'

import type { CardClusterFragment } from '~/graphql/generated'

type Props = CardClusterFragment

withDefaults(defineProps<Props>(), {
  items: () => [],
})

const { getUrl, isExternalLink } = useGetDatoUrlForLink()

function getImageWrapperEl(item: CardClusterFragment['items'][0]) {
  if (item.ctaLink && item.ctaLink.url) return resolveComponent('NuxtLink')

  return 'div'
}

function getImageWrapperElAttrs(item: CardClusterFragment['items'][0]) {
  if (item.ctaLink && item.ctaLink.url) {
    return {
      to: item.ctaLink.url,
      target: '_blank',
    }
  }
  return {}
}
</script>

<template>
  <DatoSectionContainer>
    <ul
      v-if="items.length"
      class="cardCluster -tw-mx-lg tw-flex tw-gap-x-sm tw-overflow-scroll tw-px-lg tw-pb-2xs md:-tw-mx-xl md:tw-gap-x-lg md:tw-px-xl lg:tw-mx-0 lg:tw-flex-wrap lg:tw-justify-center lg:tw-gap-x-xl lg:tw-gap-y-3xl lg:tw-overflow-visible lg:tw-px-0 lg:tw-pb-0"
    >
      <li
        v-for="(item, index) in items"
        :key="index"
        class="flex-item tw-flex tw-flex-col"
      >
        <component
          :is="getImageWrapperEl(item)"
          v-bind="getImageWrapperElAttrs(item)"
        >
          <DatocmsImage
            v-if="isNonNullAndDefined(item?.illustration?.responsiveImage)"
            :data="item.illustration.responsiveImage"
            class="dato-img tw-h-[194px] tw-min-w-[272px] tw-rounded-md md:tw-min-w-[318px]"
          />
        </component>
        <h3
          v-if="item.title"
          class="tw-text-body tw-mt-lg !tw-font-bold md:tw-text-h6"
        >
          {{ item.title }}
        </h3>
        <DatocmsStructuredText
          v-if="item.multiLineSubtitle"
          :data="item.multiLineSubtitle"
          class="tw-text-body-xs tw-mt-sm tw-flex-1 md:tw-text-body"
        />
        <UIButtonLink
          v-if="item.ctaLink && item.ctaLink.url"
          :to="getUrl(item.ctaLink.url) || ''"
          class="cardClusterLink tw-mt-sm tw-pl-0 md:tw-text-base"
          icon-right-name="mdi-chevron-right"
          :target="isExternalLink(item.ctaLink.url) ? '_blank' : undefined"
          >{{ item.ctaLink.text }}</UIButtonLink
        >
      </li>
    </ul>
  </DatoSectionContainer>
</template>

<style lang="scss" scoped>
.dato-img:deep(img) {
  @apply tw-object-cover;
}

.flex-item {
  flex: 0 0 calc(33% - 10px);
}
</style>
