import type { ChartsTrack } from '~/types/ChartsTrack'
import type { CoreFetch } from '~/types/CoreFetch'
import type { PaginatedApiResponseSansCount } from '~/types/PaginatedApiResponse'

export function provideFetchChartTracks(coreFetch: CoreFetch) {
  /**
   * Fetches the paginated list of chart tracks.
   *
   * @param cursor - Encoded strings used for "next"|"previous" urls provided by the backend (e.g. "cD0yMA%3D%3D")
   * @returns A promise that resolves to the paginated list of chart tracks.
   */
  return function (cursor?: string) {
    return coreFetch.$get<PaginatedApiResponseSansCount<ChartsTrack>>(
      '/track/chart/',
      {
        params: { cursor: decodeURIComponent(cursor || '') },
      },
    )
  }
}
