<script lang="ts" setup>
import { storeToRefs } from 'pinia'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore No Type declaration
import { RecycleScroller } from 'vue-virtual-scroller'

import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'

import ChartsTrackListItem from './ChartsTrackListItem.vue'

import { useMiscResizeStore } from '~/stores/miscResize'

import { Breakpoints } from '~/enums/breakpoints'

import type { ChartsTrack } from '~/types/ChartsTrack'

type Props = {
  trackList: ChartsTrack[]
  numTracksPreloaded: number
}

type Emits = {
  trackSelected: [payload: { trackId: number }]
  shareChartsTrack: [chartsTrack: ChartsTrack]
}

defineProps<Props>()
const emit = defineEmits<Emits>()

const { SCREEN_WIDTH } = storeToRefs(useMiscResizeStore())
const MIN_ITEMS_PER_PAGE = 20
const MOBILE_ROW_HEIGHT = 78
const TABLET_ROW_HEIGHT = 80
const DESKTOP_ROW_HEIGHT = 112

const itemSize = computed(() => {
  const itemGap = SCREEN_WIDTH.value < Breakpoints.lg ? 0 : 4
  const rowSize =
    SCREEN_WIDTH.value < Breakpoints.md
      ? MOBILE_ROW_HEIGHT
      : SCREEN_WIDTH.value < Breakpoints.lg
        ? TABLET_ROW_HEIGHT
        : DESKTOP_ROW_HEIGHT
  return rowSize + itemGap
})

function handleTrackSelected(trackId: number) {
  emit('trackSelected', { trackId })
}
</script>

<template>
  <!-- Use RecycleScroller's "list-class" prop to override default list styles -->
  <!-- In this case, ensure overflow is visible so focused element outlines aren't cut off -->
  <RecycleScroller
    id="charts-track-list"
    v-slot="{ item }"
    :buffer="itemSize * MIN_ITEMS_PER_PAGE"
    :items="trackList"
    :item-size="itemSize"
    list-tag="ol"
    item-tag="li"
    key-field="track_id"
    :prerender="numTracksPreloaded"
    page-mode
    class="tw-w-full"
    list-class="!tw-overflow-visible"
  >
    <ChartsTrackListItem
      :track-rank="item.rank"
      :artist-name="item.band_name"
      :track-url="item.track_link"
      :track-id="item.track_id"
      :track-name="item.track_name"
      @click="handleTrackSelected(item.track_id)"
      @share="emit('shareChartsTrack', item)"
      @keydown.space.prevent="handleTrackSelected(item.track_id)"
      @keydown.enter="handleTrackSelected(item.track_id)"
    />
  </RecycleScroller>
</template>
