<script setup lang="ts">
import { UIButton, UIIcon } from '@groover-dev/groover-ui'
import { useFocus } from '@vueuse/core'
import throttle from 'lodash.throttle'
import { storeToRefs } from 'pinia'

import { vRemoveBodyScroll } from '~/directives/RemoveBodyScroll'

import ChartsShareOnInstagramModal from '~/components/charts/ShareOnInstagramModal.vue'
import ChartsPlayer from '~/components/dato/Charts/ChartsPlayer.vue'
import ChartsTrackList from '~/components/dato/Charts/ChartsTrackList.vue'
import DatoSectionContainer from '~/components/dato/DatoSectionContainer.vue'

import { useProvideCoreFetch } from '~/composables/useProvideCoreFetch'

import { useMiscResizeStore } from '~/stores/miscResize'
import { useUserStore } from '~/stores/user'

import { isDefined, isNonNanNumber } from '~/utils/type-guards'

import { provideFetchChartTracks } from '~/api-core/Charts/FetchCharts'

import { Breakpoints } from '~/enums/breakpoints'

import type { ChartsFragment } from '~/graphql/generated'
import type { ChartsTrack } from '~/types/ChartsTrack'
import type { PaginatedApiResponseSansCount } from '~/types/PaginatedApiResponse'

type Props = ChartsFragment

withDefaults(defineProps<Props>(), {
  title: '',
  subtitle: '',
  cta: null,
})

const {
  query: { shareTrackId, trackId: trackIdQueryParam },
} = useRoute()
const { coreFetch } = useProvideCoreFetch()
const { SCREEN_WIDTH } = storeToRefs(useMiscResizeStore())
const { isAndroid, isIos } = useDevice()

// set up for focusing the mobile player
const mobileChartsPlayer = ref<HTMLElement | null>(null)
const { focused: isMobileChartsPlayerFocused } = useFocus(mobileChartsPlayer)

const shouldModalOpen = ref(false)
const selectedTrack = ref<ChartsTrack | undefined>()
const selectedChartsTrackToShare = ref<undefined | ChartsTrack>()

useSrollTrackIntoView()

const ctaSize = computed(() => {
  if (SCREEN_WIDTH.value <= Breakpoints.sm) return 'small'
  if (SCREEN_WIDTH.value < Breakpoints.lg) return 'medium'
  return 'large'
})

const isModalDisplayed = computed(() => {
  return (
    SCREEN_WIDTH.value < Breakpoints.md &&
    selectedTrack.value &&
    shouldModalOpen.value
  )
})

const { data: chartsData } = await useAsyncData(
  'chartsData',
  async () => {
    let tracks = {
      ...(await fetchChartsTrackResults()),
      foundTrack: undefined,
    } as PaginatedApiResponseSansCount<ChartsTrack> & {
      foundTrack?: ChartsTrack
    }
    if (!tracks.results.length) return tracks

    const trackId = Number(trackIdQueryParam) || 0
    if (!trackId) return tracks

    // when trackId query param is present, we need to fetch pages of results until we find it
    const foundTrackInFirstResultsSet = tracks.results.find(
      (track) => track.track_id === trackId,
    )
    if (foundTrackInFirstResultsSet)
      return { ...tracks, foundTrack: foundTrackInFirstResultsSet }

    if (!tracks.next) return tracks

    const foundTracks = await fetchChartTracksUntilTrackIsFound(
      trackId,
      getCursorFromUrl(tracks.next || ''),
      tracks.results,
    )

    if (foundTracks.results.length) {
      tracks = {
        ...foundTracks,
        results: foundTracks.results,
        next: foundTracks.next,
      }

      return tracks
    }
  },
  {
    default: () => ({
      next: null,
      previous: null,
      results: [],
      foundTrack: undefined,
    }),
  },
)

// set first track to be selected by default
selectedTrack.value = chartsData.value?.results[0]

onMounted(() => {
  if (!isAndroid && !isIos) return

  const parsedNumber = Number(shareTrackId)

  if (!isNonNanNumber(parsedNumber)) return

  const chartsTrack = chartsData.value?.results.find(
    ({ track_id }) => track_id === parsedNumber,
  )

  if (!chartsTrack) return
  selectedChartsTrackToShare.value = chartsTrack
})

const throttledHandleTrackSelected = throttle(handleTrackSelected, 350, {
  trailing: false,
})

async function fetchChartTracksUntilTrackIsFound(
  trackId: number,
  cursor: string,
  prevResults: ChartsTrack[],
): Promise<
  PaginatedApiResponseSansCount<ChartsTrack> & { foundTrack?: ChartsTrack }
> {
  const tracks = await fetchChartsTrackResults(cursor)

  // there are no more results to look through
  if (!tracks.results.length) return tracks

  // add previous results to the current results
  tracks.results = [...prevResults, ...tracks.results]

  const foundTrack = tracks.results.find((track) => track.track_id === trackId)
  // if we found the track or there aren't anymore pages to search for return
  if (foundTrack || !tracks.next) return { ...tracks, foundTrack }

  // if we haven't found the track yet, keep looking
  const nextCursor = getCursorFromUrl(tracks.next || '')
  return fetchChartTracksUntilTrackIsFound(trackId, nextCursor, tracks.results)
}

async function fetchChartsTrackResults(cursor?: string) {
  const fetchChartTracks = provideFetchChartTracks(coreFetch)
  return await fetchChartTracks(cursor).catch(() => ({
    next: null,
    previous: null,
    results: [],
  }))
}

function getCursorFromUrl(url: string) {
  return url.split('?cursor=')[1]
}

function handleTrackSelected(payload: { trackId: number }) {
  selectedTrack.value = chartsData.value?.results.find(
    (track) => track.track_id === payload.trackId,
  )
  shouldModalOpen.value = true
}

function handleMobileChartsPlayerClose() {
  shouldModalOpen.value = false
}

async function handleLoadMoreClick() {
  if (!chartsData.value?.next) return

  const cursor = getCursorFromUrl(chartsData.value.next)
  if (!cursor) return

  const results = await fetchChartsTrackResults(cursor)
  chartsData.value = {
    ...chartsData.value,
    results: [...(chartsData.value?.results || []), ...results.results],
    next: results.next,
  }
}
/**
 * Inline composable for scrolling the track into view.
 *
 * When the route has a "trackId" query param, we need to scroll to the correct track on the page
 * this can be tricky since the track list is virtualized with Recycle Scroller
 * Two things to note:
 *   1. We're using useAsyncData to get all tracks needed so that the data is preloaded before we scroll
 *   2. Since Recycle Scroller only renders a certain number of items, we may need to scroll down to load more items
 *      before we can scroll to the correct track
 */
function useSrollTrackIntoView() {
  const { IS_LOGGED_IN } = storeToRefs(useUserStore())

  onMounted(() => {
    const trackId = Number(trackIdQueryParam) || 0

    // only scroll if the trackId is a number and the track has been found in the data
    if (trackId && chartsData.value?.foundTrack) {
      handleTrackSelected({ trackId })
      handleMobileChartsPlayerClose()

      // using raf ensures that Recycle Scroller has rendered the list and applied its styling before we check for the element
      requestAnimationFrame(() => {
        const el = document.getElementById(`track-${trackId}`)
        recursivelyScrollElementIntoView(el)
      })
    }
  })

  function recursivelyScrollElementIntoView(el: HTMLElement | null) {
    const { top } = el?.getBoundingClientRect() || { top: 0 }
    const elDistanceFromTop = window.pageYOffset + top

    // the element is visible in the viewport so we can scroll directly to it while offsetting the navbar height
    if (el && elDistanceFromTop >= 0) {
      requestAnimationFrame(() => {
        const DEFAULT_NAV_BAR_HEIGHT = IS_LOGGED_IN.value ? 0 : 76
        const ADDITIONAL_PADDING_OFFSET = 8
        const { height: navbarHeight } = (
          document.querySelector('header') as HTMLElement | null
        )?.getBoundingClientRect() || { height: DEFAULT_NAV_BAR_HEIGHT }
        const offsetPosition =
          elDistanceFromTop - navbarHeight - ADDITIONAL_PADDING_OFFSET
        const scrollingElement =
          document.scrollingElement || document.documentElement

        scrollingElement.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        })
      })

      return
    }

    /*
     * If the element isn't in the viewport, scroll down to where it should be be based on its array index & row height.
     * The Recycle Scroller will load more items so we can pinpoint the element afterwards.
     */
    const MIN_POSSIBLE_ITEMS = 20
    const itemRowHeight = el?.offsetHeight || 78 // 78 is min height of a track item
    const recycleScrollerEl = document.getElementById(
      'charts-track-list',
    ) as HTMLElement | null
    const trackIndexPosition =
      chartsData.value?.results.findIndex(
        (track) => track.track_id === chartsData.value?.foundTrack?.track_id,
      ) || 0
    const listPosFromTop =
      recycleScrollerEl?.getBoundingClientRect().top ?? 0 + window.scrollY
    const scrollHeight =
      (trackIndexPosition > 0 ? trackIndexPosition : MIN_POSSIBLE_ITEMS) *
        itemRowHeight +
      listPosFromTop

    // Using a timeout gives time for the scrolling animation to run and for the Recycle Scroller to load more items
    return setTimeout(() => {
      const scrollingElement =
        document.scrollingElement || document.documentElement
      scrollingElement.scrollTop = scrollingElement.scrollTop + scrollHeight

      return recursivelyScrollElementIntoView(el)
    }, 650)
  }
}
</script>

<template>
  <DatoSectionContainer class="tw-relative tw-flex-col">
    <ChartsShareOnInstagramModal
      v-if="selectedChartsTrackToShare"
      :charts-track="selectedChartsTrackToShare"
      :display="isDefined(selectedChartsTrackToShare)"
      @update:display="selectedChartsTrackToShare = undefined"
    />
    <div class="tw-flex tw-flex-col tw-gap-lg md:tw-gap-y-2xl lg:tw-flex-row">
      <div
        v-if="title || subtitle"
        class="tw-flex tw-w-full tw-flex-col tw-gap-y-lg md:tw-gap-y-sm"
      >
        <h2 v-if="title" class="tw-text-h3">{{ title }}</h2>
        <p v-if="subtitle" class="tw-text-body-sm md:tw-text-body">
          {{ subtitle }}
        </p>
      </div>
      <UIButton
        v-if="cta?.url"
        :to="cta.url"
        :text="cta.text"
        target="_blank"
        class="tw-bg-orange-500 md:tw-place-self-start lg:tw-place-self-end"
        :size="ctaSize"
      />
    </div>
    <hr
      v-if="title || subtitle || cta"
      class="tw-border-discrete-3 tw-m-auto tw-mt-2xl tw-hidden tw-w-full md:tw-block"
    />
    <div
      v-if="chartsData?.results.length"
      class="md:tw-grid md:tw-grid-cols-[1fr_40.5%] md:tw-items-start md:tw-gap-x-lg"
      :class="{
        'tw-mt-2xl md:tw-mt-4xl lg:tw-mt-6xl': title || subtitle || cta,
      }"
    >
      <ChartsTrackList
        :track-list="chartsData.results"
        :num-tracks-preloaded="chartsData.results.length"
        @track-selected="throttledHandleTrackSelected"
        @share-charts-track="selectedChartsTrackToShare = $event"
      />
      <!-- Mobile Load more button -->
      <UIButton
        v-if="chartsData?.next"
        :text="$t('common.loadMore')"
        class="tw-mt-2xl tw-w-full tw-bg-orange-500 md:tw-hidden"
        @click="handleLoadMoreClick"
      />
      <!-- Mobile "listen now" button -->
      <button
        type="button"
        class="tw-sticky tw-bottom-lg tw-left-0 tw-z-50 tw-mt-xl tw-w-full tw-rounded-lg tw-bg-fill-alt tw-p-sm tw-pt-2xs tw-shadow-xl md:tw-hidden"
        @click="shouldModalOpen = true"
      >
        <span class="tw-flex tw-flex-col tw-items-center">
          <UIIcon
            name="mdi:chevron-up"
            size="sm"
            class="tw-text-icon-discrete3"
            aria-hidden
          />
          <span class="tw-text-body-sm tw-text-inverse">{{
            $t('common.listenNow')
          }}</span>
        </span>
      </button>
      <!-- Mobile player -->
      <ClientOnly>
        <Teleport to="#modal-backdrop-overlay">
          <Transition
            name="fade"
            @after-enter="isMobileChartsPlayerFocused = true"
          >
            <!-- use of v-show & v-if on the same element is intentional -->
            <!-- v-show allows playback to continue on mobile when a user closes the modal -->
            <div
              v-if="SCREEN_WIDTH < Breakpoints.md"
              v-show="isModalDisplayed"
              v-remove-body-scroll="isModalDisplayed"
              class="tw-fixed tw-left-0 tw-top-0 tw-z-50 tw-flex tw-min-h-dvh tw-min-w-full tw-items-end tw-bg-[#000000] tw-bg-opacity-50 tw-p-lg md:tw-hidden"
              role="dialog"
              aria-labelledby="trackName"
              @click.stop="shouldModalOpen = false"
            >
              <!-- @click.stop prevents player clicks from auto-closing the modal -->
              <ChartsPlayer
                v-if="selectedTrack"
                ref="mobileChartsPlayer"
                :track-url="selectedTrack.track_link"
                :track-artist="selectedTrack.band_name"
                :track-name="selectedTrack.track_name"
                class="tw-w-full"
                tabindex="0"
                @click.stop
                @close="handleMobileChartsPlayerClose"
              />
            </div>
          </Transition>
        </Teleport>
      </ClientOnly>
      <!-- Desktop player -->
      <ClientOnly>
        <Transition name="fade">
          <ChartsPlayer
            v-if="SCREEN_WIDTH >= Breakpoints.md && selectedTrack"
            :track-url="selectedTrack.track_link"
            :track-artist="selectedTrack.band_name"
            :track-name="selectedTrack.track_name"
            class="tw-sticky tw-right-0 tw-top-[140px]"
          />
        </Transition>
      </ClientOnly>
    </div>
    <!-- Medium + Desktop Load more button -->
    <UIButton
      v-if="chartsData?.next"
      :text="$t('common.loadMore')"
      class="tw-mx-auto tw-mt-2xl tw-hidden tw-w-full tw-bg-orange-500 md:tw-mt-4xl md:tw-block md:tw-w-fit lg:tw-mt-6xl"
      @click="handleLoadMoreClick"
    />
  </DatoSectionContainer>
</template>
