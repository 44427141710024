<script lang="ts" setup>
import {
  UIButtonLink,
  UIDropdownMenu,
  UIDropdownMenuList,
  UIDropdownMenuListItem,
} from '@groover-dev/groover-ui'
import { markRaw } from 'vue'

import { useGetDatoUrlForLink } from '~/composables/useGetDatoUrlForLink'

import type { NavbarFragment } from '~/graphql/generated'

type Props = {
  navLinks:
    | NavbarFragment['leftSideNavLinks']
    | NavbarFragment['rightSideNavLinks']
}

const props = withDefaults(defineProps<Props>(), {
  navLinks: () => [],
})

const { getUrl, isExternalLink } = useGetDatoUrlForLink()

function getFormatedLinkWhenSolo(link: Props['navLinks'][number]) {
  if (link.navbarDropdownMenuLinks.length > 1) return null
  if (!link.navbarDropdownMenuLinks.length) return null

  const singleLink = link.navbarDropdownMenuLinks[0]

  return getUrl(singleLink.url)
}

type LinkConfiguration<T = string | null> = {
  link: Props['navLinks'][number]
  isSolo: T extends string ? true : false
  isExternal: T extends string ? boolean : false
  soloLink: T
}

const configuration = computed(() => {
  return props.navLinks.reduce((accumulator, link) => {
    const formatedLink = getFormatedLinkWhenSolo(link)

    accumulator.push({
      link,
      isSolo: formatedLink !== null,
      soloLink: formatedLink,
      isExternal: isExternalLink(formatedLink),
    })

    return accumulator
  }, [] as LinkConfiguration[])
})
</script>

<template>
  <ul class="tw-flex tw-items-center">
    <li
      v-for="{ link, isSolo, soloLink, isExternal } in configuration"
      :key="link.id"
    >
      <UIDropdownMenu
        :reference-component="markRaw(UIButtonLink)"
        :reference-component-attrs="{
          role: isSolo ? 'link' : 'button',
          target: isExternal ? '_blank' : '',
          to: soloLink || '',
          size: 'small',
          color: 'neutral',
        }"
        display-menu-on-hover
        :hide-delay="250"
      >
        <template #referenceText
          ><span>{{ link.text }}</span></template
        >
        <template v-if="link.navbarDropdownMenuLinks.length > 1" #menuLists>
          <UIDropdownMenuList>
            <UIDropdownMenuListItem
              v-for="subLink in link.navbarDropdownMenuLinks"
              :key="subLink.id"
              :to="getUrl(subLink.url)"
              :text="subLink.text || ''"
              :sub-text="subLink.subtext || ''"
              :left-icon-name="subLink.iconName ? subLink.iconName : undefined"
              :data-test-id="
                getUrl(subLink.url)?.includes('/influencer/signup/')
                  ? 'curatorSignupLink'
                  : undefined
              "
            />
          </UIDropdownMenuList>
        </template>
      </UIDropdownMenu>
    </li>
  </ul>
</template>
